<script setup lang="ts">

const props = defineProps<{
  title: string,
  showClose?: boolean,
  hideTitle?: boolean
  width?: number,
}>();

const emits = defineEmits<{
  (e: 'closed'): void
}>()

const close = (v) => {
  emits('closed')
}

</script>
<template>
  <v-dialog :width="width || 500" :model-value="true" @update:model-value="close" >
    <v-card>
      <v-card-title class="pa-4 bg-lightsecondary" v-if="!hideTitle">
        <div class="d-flex justify-space-between align-center">
          <div class="ml-2">{{ title }}</div>
          <div v-if="showClose">
            <v-btn size="sm" color="" class="pa-1" icon @click="close">
              <v-icon size="20">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-title>
      <v-card-text class="pb-4">
        <slot/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
